.animation__container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 20em;
  background-color: transparent;
  margin: 0 auto;
}

.animation {
  border-radius: 50%;
}

.send-animation__container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 20em;
  max-height: 20em;
  background-color: transparent;
}
