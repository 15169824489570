.hero {
  position: relative;
  display: flex;
  align-items: flex-start;
  /* Changed from center to flex-start */
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.hero__overlay {
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: flex-start;
  /* Changed from center to flex-start */
  justify-content: center;
}

.hero__content {
  position: relative;
  z-index: 2;
  text-align: center;
  max-width: 600px;
  padding: 20px;
  color: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  /* Make it full height */
}

.hero__text {
  padding-top: 50px;
  /* Add some top padding */
}

.hero__title {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 20px;
}

.hero__description {
  font-size: 1.2rem;
  margin-bottom: 40px;
  font-weight: 400;
}

.newsletter__form {
  background-color: rgba(255, 255, 255, 0.1);
  /* Transparent form background */
  padding: 20px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 12px;
}

.newsletter__input {
  padding: 10px;
  width: 100%;
  margin-bottom: 15px;
  border-radius: 5px;
  border: none;
}

.newsletter__submit {
  padding: 10px 20px;
  background-color: var(--primary);
  color: var(--white);
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.newsletter__submit:hover {
  background-color: var(--primary-dark);
}

/* Responsive Styles */

@media (min-width: 768px) {
  .hero__title {
    font-size: 3.5rem;
  }
  .hero__description {
    font-size: 1.5rem;
  }
}

@media (min-width: 1024px) {
  .hero__title {
    font-size: 4.5rem;
  }
  .hero__description {
    font-size: 2rem;
  }
}
