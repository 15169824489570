.navbar--arrow {
  background-color: var(--light);
}

.navbar__brand--arrow {
  display: flex;
  align-items: center;
  color: var(--black);
  font-size: 1.5rem;
  font-weight: bold;
  cursor: pointer;
}

.navbar__icon--arrow {
  max-width: 3em;
  max-height: 3em;
  border-radius: 100%;
  margin-right: 0.5em;
}
