.animation__container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.homepage {
  width: 100%;
  background-color: var(--light);
}
