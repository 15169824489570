* {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  background-color: var(--light);
}

html {
  background-color: var(--light);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3 {
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  margin: 0.5em 0;
}

h1 {
  font-size: 1.6rem;
}

h2 {
  font-size: 1.4rem;
}

h3 {
  font-size: 1.2rem;
}

p {
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.7;
  margin: 0.5em 0;
}

@media only screen and (min-width: 600px) {
  h1 {
    font-size: 1.8rem;
  }
  h2 {
    font-size: 1.6rem;
  }
  h3 {
    font-size: 1.4rem;
  }
  p {
    font-size: 1.2rem;
  }
}

@media only screen and (min-width: 768px) {
  h1 {
    font-size: 2rem;
  }
  h2 {
    font-size: 1.8rem;
  }
  h3 {
    font-size: 1.6rem;
  }
  p {
    font-size: 1.4rem;
  }
}

:root {
  --black: #010a26;
  --black-hover: #323d60;
  --dark-blue: #011640;
  --dark-blue-hover: #1a2d53;
  --blue: #b6d6f2;
  --blue-hover: #bddaf3;
  --gold: #f2e6d0;
  --gold-hover: #fbf6ef;
  --pink: #fff7f5;
  --pink--hover: #faeae6;
  --light: #fcfbfa;
  --white: #ffffff;
  --gray--light: #d9d9d9;
  --gray: #8c8c8c;
  --gray--dark: #595959;
  --green: #4bb543;
  --red: #e83338;
  --box-shadow: rgba(149, 157, 165, 0.2);
  --border-radius: 0.2em;
  --max-width: 120em;
  --max-form-width: 70em;
}

.medium-margin-bottom {
  margin-bottom: 1em;
}

.medium-margin-top {
  margin-top: 1em;
}

.small-margin-top {
  margin-top: 0.5em;
}

.large-margin-bottom {
  margin-bottom: 2em;
}

.large-margin-top {
  margin-top: 2em;
}

/* Margins */

.mt-small {
  margin-top: 0.5em;
  /* adjust as needed */
}

.mt-medium {
  margin-top: 1em;
  /* adjust as needed */
}

.mt-large {
  margin-top: 2em;
  /* adjust as needed */
}

.mt-extra-large {
  margin-top: 4em;
  /* adjust as needed */
}

/* Paddings */

.pt-small {
  padding-top: 0.5em;
  /* adjust as needed */
}

.pt-medium {
  padding-top: 1em;
  /* adjust as needed */
}

.pt-large {
  padding-top: 2em;
  /* adjust as needed */
}

.pt-extra-large {
  padding-top: 4em;
  /* adjust as needed */
}
