.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  max-width: var(--max-width);
  min-height: 20em;
  margin: 0 auto;
  text-align: center;
  background-color: var(--light);
}

.contact-info__banners {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  width: 100%;
  max-width: var(--max-form-width);
  justify-items: center;
}

.banner__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 1em;
  padding: 1em;
  margin-bottom: 4em;
  min-width: 20em;
  min-height: 10em;
  width: 100%;
}

.banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  word-break: break-word;
}

.icon {
  margin-bottom: 1em;
}

.phone__link {
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.7;
  color: var(--black);
  text-decoration: none;
  margin: 0.5em 0;
}

.footer__icons {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 10px;
}

.footer__icon {
  color: var(--black);
  transition: color 0.3s ease;
}

.footer__icon:hover {
  color: var(--primary);
}

@media only screen and (min-width: 600px) {
  .phone__link {
    font-size: 1.2rem;
  }
}

@media only screen and (min-width: 768px) {
  .phone__link {
    font-size: 1.4rem;
  }
}
