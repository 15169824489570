.navbar {
  background-color: transparent;
}

.navbar__container {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: var(--max-width);
  margin: 0 auto;
}

.navbar__brand {
  color: var(--light);
  font-size: 1.5rem;
  font-weight: bold;
  max-width: 3em;
  max-height: 4em;
  border-radius: 100%;
}

.navbar__logo {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}

.navbar__toggle {
  border-color: var(--black);
}

.navbar__offcanvas {
  background: var(--light);
}

.navbar__offcanvas-header {
  border-bottom: 1px solid var(--black);
}

.navbar__offcanvas-title {
  color: var(--black);
  font-size: 1.25rem;
}

.navbar__offcanvas-body {
  padding-top: 1rem;
}

.navbar__nav {
  flex-direction: column;
}

.navbar__link:hover {
  color: var(--black-hover);
}

.navbar__link {
  color: var(--gray--dark);
  padding: 0.5rem 0;
  margin-right: 1em;
  transition: color 0.3s ease;
  font-size: 1.25rem;
  text-decoration: none;
  cursor: pointer;
  position: relative;
}

.navbar__link::after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: var(--gold);
  transition: width 0.4s;
}

.navbar__link:hover::after {
  width: 100%;
}
