.image-text-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.image-text-container__image,
.image-text-container__text {
  width: 100%;
}

.image-text-container__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-text-container__text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.image-text-container__text h1,
.image-text-container__text h2 {
  margin: 0;
}

@media (min-width: 1000px) {
  .image-text-container__image,
  .image-text-container__text {
    width: 50%;
  }
}
