html,
body {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.footer {
  flex-shrink: 0;
  background-color: var(--gold);
  padding: 20px 0;
}

.footer__section {
  margin-bottom: 20px;
  color: var(--gray--dark);
}

.footer__copyright {
  margin-top: 20px;
  text-align: center;
  border-top: 1px solid #dee2e6;
  padding-top: 20px;
  color: var(--gray--dark);
}

.footer__icons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 7em;
  margin: 0 auto;
}

.footer__icon {
}

@media (min-width: 768px) {
  .footer__section {
    text-align: left;
  }
  .footer__icons {
    margin: 0;
  }
}
