.about-hero {
  padding: 20px;
  background-color: #fafafa;
}

.about-hero__title {
  font-size: 2em;
  color: #333;
  margin-bottom: 10px;
}

.about-hero__paragraph {
  font-size: 1.2em;
  color: #666;
  margin-bottom: 20px;
}

.about-hero__subtitle {
  font-size: 1.6em;
  color: #333;
  margin-top: 20px;
  margin-bottom: 10px;
}

.about-hero__timeline {
  margin-bottom: 20px;
}

.about-hero__image {
  width: 100%;
  height: auto;
  margin-top: 10px;
}
