:root {
    --primary: #4a90e2;
    --primary-dark: #3a7bc8;
    --primary-light: #6aa9e9;
    --text-dark: #333333;
    --text-light: #ffffff;
    --background: #f7f9fc;
    --shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    --transition: all 0.3s ease;
}

.newsletter__container {
    position: relative;
    width: 100%;
    max-width: 450px;
    margin: 40px auto;
    padding: 30px;
    background-color: var(--background);
    border-radius: 20px;
    box-shadow: var(--shadow);
}

.newsletter__image {
    width: 160px;
    height: 160px;
    object-fit: cover;
    border-radius: 50%;
    margin: -100px auto 30px;
    display: block;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    border: 5px solid var(--background);
}

.newsletter__form {
    background-color: #ffffff;
    padding: 40px;
    border-radius: 15px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
}

.newsletter__input-group {
    position: relative;
    margin-bottom: 25px;
    margin-top: 50px;
}

.newsletter__label {
    position: absolute;
    top: -30px;
    left: 15px;
    background: transparent;
    padding: 0 5px;
    font-weight: 600;
    color: var(--text-dark);
    font-size: 18px;
}

.newsletter__input {
    width: 100%;
    padding: 15px;
    border: 2px solid #e1e5ee;
    border-radius: 10px;
    font-size: 16px;
    transition: var(--transition);
}

.newsletter__input:focus {
    border-color: var(--primary);
    outline: none;
    box-shadow: 0 0 0 4px rgba(74, 144, 226, 0.1);
}

.newsletter__submit {
    width: 100%;
    padding: 15px;
    background: var(--primary);
    color: var(--text-light);
    border: none;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
    transition: var(--transition);
}

.newsletter__submit:hover {
    background: var(--primary-dark);
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(74, 144, 226, 0.3);
}

.newsletter__submit:active {
    transform: translateY(0);
}

.newsletter__status {
    margin-top: 25px;
    padding: 15px;
    border-radius: 10px;
    font-size: 16px;
    text-align: center;
    font-weight: 500;
    transition: var(--transition);
}

.newsletter__status--error {
    background-color: #fff5f5;
    color: #c53030;
    border: 1px solid #feb2b2;
}

.newsletter__status--success {
    background-color: #f0fff4;
    color: #2f855a;
    border: 1px solid #9ae6b4;
}

.newsletter__status--sending {
    background-color: #ebf8ff;
    color: #2b6cb0;
    border: 1px solid #bee3f8;
}

@media (max-width: 480px) {
    .newsletter__container {
        padding: 20px;
        margin: 20px auto;
    }
    .newsletter__form {
        padding: 30px;
    }
    .newsletter__input,
    .newsletter__submit {
        font-size: 16px;
        padding: 12px;
    }
    .newsletter__label {
        font-size: 16px;
    }
    .newsletter__image {
        width: 120px;
        height: 120px;
        margin: -80px auto 20px;
    }
}